
import { defineComponent, ref, reactive, toRefs, computed, toRaw, onMounted } from 'vue'
import uploadImage from '@/views/courseManger/componets/upload-image.vue'
import uploadVideo from '@/views/exerciseManager/components/upload-video.vue'
import { ValidateErrorEntity, RuleObject } from 'ant-design-vue/es/form/interface'
import { SEND_TYPE, OSSURL } from '@/hooks/ali-oss'
import { PictureOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { createExe, fetchExerciseDetail, putExercise, exerciseTag } from '@/api/index'
import { EXERISE, EXERISE_TAG } from '@/hooks/config'
import { useRoute, useRouter } from 'vue-router'
import previewDialog from '@/views/examManager/components/previewDialog.vue'
let routerName: any = ''
const columns = [
  {
    title: '选项',
    dataIndex: 'key',
    key: 'key',
    align: 'center',
    width: 80
  },
  {
    title: '选项文字',
    dataIndex: 'content',
    key: 'content',
    slots: { customRender: 'content' }
  },
  {
    title: '图片',
    dataIndex: 'img',
    key: 'img',
    slots: { customRender: 'img' }
  },
  {
    title: '是否正确',
    dataIndex: 'isCorrect',
    key: 'isCorrect',
    slots: { customRender: 'correct' },
    align: 'center'
  }
]
interface FileProps {
  file?: object;
  file_name?: string;
  file_path?: string;
  file_size?: string;
  file_type?: string;
  url?: string;
  [key: string]: any;
}
// interface QuestionProps {
//   text: string;
//   image: string;
//   audio: string;
//   video: string;
//   events: null;
// }
// interface ContentProps {
//   question: QuestionProps;
//   answers: boolean;
// }
interface FormState {
  type: number | any;
  tag_id: number;
  remarks: string;
  answers: boolean;
  text: string;
  image: string | undefined;
  video: string | undefined;
}
export default defineComponent({
  name: 'createJudge',
  props: ['id'],
  components: {
    uploadImage,
    previewDialog
    // uploadVideo
  },
  setup (props) {
    const route = useRoute()
    const router = useRouter()
    const typeList = ref(EXERISE)
    const tagList = ref()
    const formRef = ref()
    const formState: FormState = reactive({
      type: typeList.value.find(item => item.router === route.name)?.id,
      tag_id: 1,
      remarks: '',
      answers: true,
      text: '',
      image: '',
      video: ''
    })
    const getExamTag = () => {
      exerciseTag({}).then(res => {
        tagList.value = res.data
        formState.tag_id = tagList.value[0].id
      })
    }
    // 表单验证
    const rules = {
      text: [{ required: true, message: '请输入题干', trigger: 'blur', whitespace: true }]
    }
    const upload1 = ref()
    const upload2 = ref()
    const listIcon: FileProps = ref([])
    const listImageTarget: FileProps = ref([])
    const upObj = ref(SEND_TYPE)
    const videoUpObj = ref([SEND_TYPE[3]])
    const changeFileIcon = (lists: []) => {
      listIcon.value = lists
    }
    const changeFileImageTarget = (lists: []) => {
      listImageTarget.value = lists
    }
    const resetListIcon = () => {
      listIcon.value.pop()
    }
    const resetImageTarget = () => {
      listImageTarget.value.pop()
    }
    const iconUrl = computed(() => {
      if (listIcon.value.length === 0) {
        return ''
      } else {
        return (listIcon.value[0] as FileProps).file_path
      }
    })
    const videoUrl = computed(() => {
      if (listImageTarget.value.length === 0) {
        return ''
      } else {
        return (listImageTarget.value[0] as FileProps).file_path
      }
    })
    // 预览弹出层
    const previewVisible = ref(false)
    const previewId = ref(0)
    const openPreview = (id: number) => {
      previewId.value = id
      previewVisible.value = true
    }
    // 创建
    const createExerciseHttp = (data: any) => {
      createExe(data).then(res => {
        // message.success('创建成功')
        openPreview(res.data)
        // router.push({ name: 'ExerciseManager' })
      })
    }
    // 编辑
    const updataExercise = (data: any) => {
      putExercise(Object.assign(data, { id: props.id })).then(res => {
        // message.success('编辑成功')
        // router.back()
        openPreview(props.id)
        // router.push({ name: 'ExerciseManager' })
      })
    }
    const createAndUpdate = () => {
      const _data: any = {
        type: formState.type,
        tag_id: formState.tag_id,
        content: {
          question: {
            text: formState.text,
            image: iconUrl.value,
            audio: '',
            video: videoUrl.value,
            events: null
          },
          answers: formState.answers
        },
        remarks: formState.remarks
      }
      _data.content = JSON.stringify(_data.content)
      if (props.id) { // 编辑
        updataExercise(_data)
      } else { // 创建
        console.log(formState)
        createExerciseHttp(_data)
      }
    }
    const saveForm = () => {
      formRef.value.validate().then(() => {
        createAndUpdate()
      }).catch((error: ValidateErrorEntity<FormState>) => {
        console.log('error', error)
      })
    }
    // 获取详情
    const getDetail = () => {
      fetchExerciseDetail({ id: props.id }).then(res => {
        console.log(res)
        const _content = JSON.parse(res.data.content)
        listIcon.value = _content.question.image ? [{
          url: OSSURL + '/' + _content.question.image,
          file_type: 'p1',
          file_path: _content.question.image
        }] : []
        listImageTarget.value = _content.question.video ? [{
          url: OSSURL + '/' + _content.question.video,
          file_type: 'm4',
          file_path: _content.question.video
        }] : []
        formState.tag_id = res.data.tag_id
        formState.remarks = res.data.remarks
        formState.answers = _content.answers
        formState.text = _content.question.text
      })
    }
    const handelCancel = () => {
      console.log('hdsj')
      router.push({ name: routerName.name, params: { id: routerName.params.id } })
    }
    onMounted(() => {
      getExamTag()
      if (props.id) {
        getDetail()
      }
    })
    return {
      previewVisible,
      previewId,
      formRef,
      typeList,
      rules,
      formState,
      columns,
      ...toRefs(formState),
      upload1,
      upload2,
      listIcon,
      listImageTarget,
      upObj,
      videoUpObj,
      changeFileIcon,
      changeFileImageTarget,
      resetListIcon,
      resetImageTarget,
      saveForm,
      tagList,
      handelCancel
    }
  },
  beforeRouteEnter (to, from, next) {
    routerName = from
    next()
  }
})
